<template>
  <div class="teach-place-manage-container">
    <!-- 按钮区域 -->
    <el-row>
      <el-button type="primary" icon="el-icon-upload2">导入</el-button>
      <el-button
        @click="handleAddTeachPlaceBtnClick"
        type="primary"
        v-throttle
        icon="el-icon-circle-plus-outline"
        >新增教学点</el-button
      >
    </el-row>
    <!-- 查询区域 -->
    <el-row style="margin-top: 20px">
      <span style="margin-right: 10px">教学点编号/名称</span>
      <el-input
        v-model="teachPlaceCondition"
        placeholder="请输入"
        style="width: 250px; margin-right: 20px"
      ></el-input>
      <el-button v-throttle @click="handleResetTeachPlaceBtnClick"
        >重置</el-button
      >
      <el-button
        v-throttle
        type="primary"
        @click="handleQueryTeachPlaceBtnClick"
        >查询</el-button
      >
    </el-row>
    <!-- 表格区域 -->
    <el-row style="margin-top: 20px">
      <el-table
        :data="teachPlaceTableData"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="教学点编号" prop="code"></el-table-column>
        <el-table-column label="教学点名称" prop="name"></el-table-column>
        <el-table-column label="关联学校"></el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scoped">
            <div style="display: flex; margin-left: 110px">
              <div
                style="
                  margin-right: 10px;
                  cursor: pointer;
                  color: green;
                  text-decoration: underline;
                "
                @click="handleEditTeachPlaceBtnClick(scoped.row)"
              >
                编辑
              </div>
              <div
                @click="handleDeleteTeachPlaceBtnClick(scoped.row)"
                style="cursor: pointer; color: red; text-decoration: underline"
              >
                删除
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeTeachPlacePageInfo"
        @current-change="handleCurrentChangeTeachPlacePageInfo"
        :current-page="teachPlacePageInfo.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="teachPlacePageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="teachPlacePageInfo.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 新增或编辑教学点弹框 -->
    <el-dialog
      :title="addOrEditTeachPlace === 1 ? '新增教学点' : '编辑教学点'"
      :visible.sync="teachPlaceDialogVisible"
      width="600px"
    >
      <div style="width: 550px; margin: 0 auto">
        <el-form
          :model="teachPlaceForm"
          ref="teachPlaceForm"
          class="teachPlaceForm"
          :rules="rules"
        >
          <el-form-item prop="name">
            <span class="redStar">教学点名称：</span>
            <el-input
              v-model.trim="teachPlaceForm.name"
              placeholder="请输入"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <span class="redStar">教学点编号：</span>
            <el-input
              v-model.trim="teachPlaceForm.code"
              placeholder="请输入"
              style="width: 400px"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="sysOrgSchoolId">
            <span class="redStar" style="margin-left: 14px">关联学校：</span>
            <el-select
              placeholder="请选择"
              style="width: 400px"
              v-model="teachPlaceForm.sysOrgSchoolId"
            ></el-select>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <el-button type="primary" v-throttle @click="handleConfirmBtnClick"
            >确认</el-button
          >
          <el-button
            v-throttle
            @click="
              () => {
                teachPlaceDialogVisible = false;
              }
            "
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTeachPlaceListApi,
  getTeachPlaceDetailApi,
  deleteTeachPlaceApi,
  addTeachPlaceApi,
  updateTeachPlaceApi,
} from "@/api/mechanism/teachPlaceManage.js";
export default {
  name: "teachPlaceManage",
  data() {
    const checkCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z]/g;
      if (reg.test(this.teachPlaceForm.code)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      addOrEditTeachPlace: 1, // 1 新增 0 编辑
      teachPlaceCondition: "",
      teachPlaceTableData: [],
      teachPlacePageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      teachPlaceDialogVisible: false,
      teachPlaceForm: {
        name: "",
        code: "",
        sysOrgSchoolId: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入教学点名称", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入教学点编号", trigger: "blur" },
          { validator: checkCode, trigger: "blur" },
        ],
      },
      sysOrgTeachPlaceId: "",
    };
  },
  created() {
    this.fetchTeachPlaceListInfo();
  },
  methods: {
    // 获取教学点数据
    fetchTeachPlaceListInfo() {
      let fd = new FormData();
      fd.append("query", this.teachPlaceCondition);
      getTeachPlaceListApi(
        this.teachPlacePageInfo.pageIndex,
        this.teachPlacePageInfo.pageSize,
        fd
      ).then((res) => {
        if (res.success) {
          this.teachPlaceTableData = res.data;
          this.teachPlacePageInfo.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 新增教学点按钮
    handleAddTeachPlaceBtnClick() {
      this.addOrEditTeachPlace = 1;
      this.teachPlaceForm = {
        name: "",
        code: "",
        sysOrgSchoolId: "",
      };
      this.teachPlaceDialogVisible = true;
    },
    // 编辑教学点按钮
    handleEditTeachPlaceBtnClick(row) {
      getTeachPlaceDetailApi(row.sysOrgTeachPlaceId).then((res) => {
        if (res.success) {
          const { data } = res;
          this.addOrEditTeachPlace = 0;
          this.teachPlaceForm = {
            name: data.name,
            code: data.code,
            sysOrgSchoolId: data.sysOrgSchoolId,
          };
          this.sysOrgTeachPlaceId = row.sysOrgTeachPlaceId;
          this.teachPlaceDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 确认按钮点击
    handleConfirmBtnClick() {
      this.$refs.teachPlaceForm.validate((valid) => {
        if (valid) {
          if (this.teachPlaceForm.sysOrgSchoolId === "") {
            this.$message.warning("请选择关联学校");
            return;
          }
          if (this.addOrEditTeachPlace === 1) {
            // 新增
            let addParams = {
              ...this.teachPlaceForm,
            };
            addTeachPlaceApi(addParams).then((res) => {
              if (res.success) {
                this.$message.success("新增成功!");
                this.fetchTeachPlaceListInfo();
                this.teachPlaceDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 编辑
            let editParams = {
              ...this.teachPlaceForm,
              sysOrgTeachPlaceId: this.sysOrgTeachPlaceId,
            };
            updateTeachPlaceApi(editParams).then((res) => {
              if (res.success) {
                this.$message.success("编辑成功!");
                this.fetchTeachPlaceListInfo();
                this.teachPlaceDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 删除教学点按钮
    handleDeleteTeachPlaceBtnClick(row) {
      this.$confirm(`确定删除该教学点吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTeachPlaceApi([row.sysOrgTeachPlaceId]).then((res) => {
            if (res.success) {
              this.$message.success("删除成功!");
              this.fetchTeachPlaceListInfo();
            } else {
              this.$message.error(res.msg);
              return;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消删除`,
          });
        });
    },
    // 查询按钮
    handleQueryTeachPlaceBtnClick() {
      this.teachPlacePageInfo.pageIndex = 1;
      this.teachPlacePageInfo.pageSize = 10;
      this.fetchTeachPlaceListInfo();
    },
    // 重置按钮
    handleResetTeachPlaceBtnClick() {
      this.teachPlaceCondition = "";
      this.handleQueryTeachPlaceBtnClick();
    },
    // 分页修改当前页
    handleCurrentChangeTeachPlacePageInfo(val) {
      this.teachPlacePageInfo.pageIndex = val;
      this.fetchTeachPlaceListInfo();
    },
    // 分页修改页容量
    handleSizeChangeTeachPlacePageInfo(val) {
      this.teachPlacePageInfo.pageIndex = 1;
      this.teachPlacePageInfo.pageSize = val;
      this.fetchTeachPlaceListInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.redStar::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
::v-deep .teachPlaceForm {
  .el-form-item__error {
    margin-left: 100px;
  }
}
</style>
