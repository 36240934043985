import { request, noTimeOutReq } from "@/utils/request.js";

/// 新增【教学点】
export function addTeachPlaceApi(data) {
  return request({
    url: "/sys/org/teach/place/add",
    method: "post",
    data,
  });
}

// 删除【教学点】,慎用
export function deleteTeachPlaceApi(data) {
  return request({
    url: "/sys/org/teach/place/delete",
    method: "post",
    data,
  });
}

// 获取【教学点】详情
export function getTeachPlaceDetailApi(data) {
  return request({
    url: "/sys/org/teach/place/get",
    method: "post",
    data,
  });
}

// 展示所有【教学点】
export function getTeachPlaceListApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/org/teach/place/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 修改【教学点】
export function updateTeachPlaceApi(data) {
    return request({
      url: "/sys/org/teach/place/update",
      method: "post",
      data,
    });
  }